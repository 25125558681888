import _mergeJSXProps from "babel-helper-vue-jsx-merge-props";
export default {
  name: 'SunriseIcon',
  props: {
    size: {
      type: String,
      default: '24',
      validator: function validator(s) {
        return !isNaN(s) || s.length >= 2 && !isNaN(s.slice(0, s.length - 1)) && s.slice(-1) === 'x';
      }
    }
  },
  functional: true,
  render: function render(h, ctx) {
    var size = ctx.props.size.slice(-1) === 'x' ? ctx.props.size.slice(0, ctx.props.size.length - 1) + 'em' : parseInt(ctx.props.size) + 'px';
    var attrs = ctx.data.attrs || {};
    attrs.width = attrs.width || size;
    attrs.height = attrs.height || size;
    ctx.data.attrs = attrs;
    return h("svg", _mergeJSXProps([{
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
        stroke: "currentColor",
        "stroke-width": "2",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      },
      "class": "feather feather-sunrise"
    }, ctx.data]), [h("path", {
      attrs: {
        d: "M17 18a5 5 0 0 0-10 0"
      }
    }), h("line", {
      attrs: {
        x1: "12",
        y1: "2",
        x2: "12",
        y2: "9"
      }
    }), h("line", {
      attrs: {
        x1: "4.22",
        y1: "10.22",
        x2: "5.64",
        y2: "11.64"
      }
    }), h("line", {
      attrs: {
        x1: "1",
        y1: "18",
        x2: "3",
        y2: "18"
      }
    }), h("line", {
      attrs: {
        x1: "21",
        y1: "18",
        x2: "23",
        y2: "18"
      }
    }), h("line", {
      attrs: {
        x1: "18.36",
        y1: "11.64",
        x2: "19.78",
        y2: "10.22"
      }
    }), h("line", {
      attrs: {
        x1: "23",
        y1: "22",
        x2: "1",
        y2: "22"
      }
    }), h("polyline", {
      attrs: {
        points: "8 6 12 2 16 6"
      }
    })]);
  }
};